@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-grd {
  background: hsla(113, 96%, 81%, 1) !important;
  background: linear-gradient(45deg, hsla(113, 96%, 81%, 1) 0%, hsla(188, 90%, 51%, 1) 100%) !important;
  background: -moz-linear-gradient(45deg, hsla(113, 96%, 81%, 1) 0%, hsla(188, 90%, 51%, 1) 100%) !important;
  background: -webkit-linear-gradient(45deg, hsla(113, 96%, 81%, 1) 0%, hsla(188, 90%, 51%, 1) 100%) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ADFDA2", endColorstr="#11D3F3", GradientType=1);
}

.site-layout {
  background-color: #414142;
  z-index: 99;
}
.ant-layout-sider,.ant-layout-header,.ant-menu-dark{
  background-color: #050f20 !important
}

.main-layout {
  border-radius: 25px 0 0 0;
  background-color: #f0f2f5;
}


.align-right-label .ant-form-item-label {
  text-align: right
}

/* Alert Card Styles */
.form-data-modal .ant-modal-header{
  position: absolute;
}
.form-data-modal .ant-modal-title{
  display: none;
}
.form-data-modal .ant-modal-body{
  padding: 0;
}
.form-data-modal .close-btn{
  top: 15px;
  right: 15px;
  position: absolute;
  width: 45px;
  height: 45px;
  line-height: 42px;
}

/* Login page */

.login-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

/* Components Custom Styles */
.ant-switch {
  background-color: #cfcfcf !important;
}

.ant-switch-checked, .ant-modal-footer .ant-btn.ant-btn-primary, .ant-btn-primary {
  background: #f59e0b !important;
}

.ant-modal-footer .ant-btn {
  border: none;
  border-radius: 8px;
}

.ant-btn-primary {
  border: none;
}


